export default {
  data() {
    return {
      formEdit: {
        collectFactoryEditList: [
          {
            name: "",
            addressInfo: {
              zipCode: "",
              prefecturesId: null,
              address: "",
              buildingName: "",
            },
            tel: "",
            fax: "",
            jwnetContractNo: "",
            ediKey: "",
            publicConfirmationNo: "",
            collectOfficeList: null,
          },
        ]
      },
      dummyFormEdit: {
        collectFactory: {
          id: null,
          name: "",
          addressInfo: {
            zipCode: "",
            prefecturesId: null,
            selectedPrefectureTemp: "",
            address: "",
            buildingName: "",
          },
          tel: "",
          fax: "",
          jwnetContractNo: "",
          ediKey: "",
          publicConfirmationNo: "",
          collectOfficeList: [
            {
              id: null,
              name: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: null,
                selectedPrefectureTemp: "",
                address: "",
                buildingName: "",
              },
              tel: "",
              fax: "",
            },
          ],
        },
        collectOffice: {
          id: null,
          name: "",
          addressInfo: {
            zipCode: "",
            prefecturesId: null,
            selectedPrefectureTemp: "",
            address: "",
            buildingName: "",
          },
          tel: "",
          fax: "",
        },
      },
    };
  },
};
